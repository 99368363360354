import React, { Component } from 'react'
import Helmet from 'react-helmet'
import urljoin from 'url-join'
import config from '../../../data/SiteConfig'

const { seo } = config

class SEO extends Component {
  render() {
    const { title } = this.props
    const image = config.siteLogo
    const blogURL = urljoin(config.siteUrl, config.pathPrefix)
    const schemaOrgJSONLD = [
      {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        url: blogURL,
        name: title,
        alternateName: config.siteTitleAlt ? config.siteTitleAlt : ''
      }
    ]
    return (
      <Helmet>
        {/* General tags */}
        <title>{`${title} | ${config.siteTitle}`}</title>
        <meta name="description" content={config.siteDescription} />
        {image && <meta name="image" content={image} />}

        {/* Schema.org tags */}
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>

        {seo.og && seo.og.site_name && (
          <meta property="og:site_name" content={seo.og.site_name} />
        )}
        <meta property="og:url" content={seo.og.url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={seo.og.title} />
        <meta property="og:description" content={seo.og.description} />
        <meta property="og:image" content={seo.og.image} />
        <meta property="og:image:alt" content={seo.og.description} />

        {seo.twitter && seo.twitter.creator && (
          <meta name="twitter:creator" content={seo.twitter.creator} />
        )}
        <meta name="twitter:card" content={seo.twitter.type} />
        <meta name="twitter:title" content={seo.twitter.title} />
        <meta name="twitter:description" content={seo.twitter.description} />
        <meta name="twitter:image" content={seo.twitter.image} />
        <meta name="twitter:image:alt" content={seo.twitter.description} />
      </Helmet>
    )
  }
}

export default SEO
