import React from 'react'
import { Link } from 'gatsby'
import { Field, Formik, Form } from 'formik'
import * as Yup from 'yup'
import {
  Button,
  Checkbox,
  Label,
  Control,
  CustomInput
} from 'sqrl-design-system'

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const RenderCheckbox = ({ label, ...rest }) => {
  return (
    <Control {...rest}>
      <Label className="checkbox">
        <Checkbox type="checkbox" {...rest.field} />
        <span className="small-text" style={{ marginLeft: 10 }}>
          {label}
        </span>
      </Label>
    </Control>
  )
}

const LoginSchema = Yup.object().shape({
  method: Yup.string().required().default('SQRL'),
  email: Yup.string()
    .required('Email is required')
    .email('Email is invalid'),
  password: Yup.string()
    .required('Password is required')
    .min(4, 'Password too short'),
  rememberDevice: Yup.bool().oneOf([true, false]).default(false)
})

const initialValues = LoginSchema.cast()

const LoginForm = () => (
  <div>
    <Formik
      initialValues={initialValues}
      validationSchema={LoginSchema}
      onSubmit={(values, { setSubmitting }) => {
        console.log(values)
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({ 'form-name': 'login', ...values })
        })
          .then(() => {
            alert('Success!')
            setSubmitting(false)
          })
          .catch(error => alert(error))
      }}
      render={props => (
        <Form
          className="form-login"
          name="login"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="login" />

          <Field
            className={
              props.touched.email && props.errors.email ? 'is-invalid' : ''
            }
            type="email"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.email}
            id="email"
            name="email"
            label="Email Address"
            component={CustomInput}
          />

          <div className="password-compound">
            <Link to="/forgot-password">Forgot Password?</Link>
            <Field
              className={
                props.touched.password && props.errors.password
                  ? 'is-invalid'
                  : ''
              }
              type="password"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.password}
              id="password"
              name="password"
              label="Password"
              component={CustomInput}
            />
          </div>

          <Field
            component={RenderCheckbox}
            type="checkbox"
            name="rememberDevice"
            id="rememberDevice"
            label="Remember this device"
            style={{ fontSize: 14, fontWeight: 500 }}
          />

          <Button
            isColor={props.isValid ? 'primary' : ''}
            className="is-rounded local-auth"
            type="submit"
          >
            Sign In
          </Button>
        </Form>
      )}
    />
  </div>
)

export default LoginForm
