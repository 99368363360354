import React from 'react'

export default () => (
  <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1">
    <g
      id="App-Site"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Mobile-/-SQRL-App-Menu"
        transform="translate(-26.000000, -31.000000)"
        fill="#FFFFFF"
      >
        <g id="Group-9" transform="translate(25.000000, 30.000000)">
          <g id="Group-12">
            <rect
              id="Rectangle-13-Copy-2"
              transform="translate(8.000000, 8.000000) rotate(45.000000) translate(-8.000000, -8.000000) "
              x="-1"
              y="7"
              width="18"
              height="2"
            />
            <rect
              id="Path"
              transform="translate(8.000000, 8.000000) rotate(-45.000000) translate(-8.000000, -8.000000) "
              x="-1"
              y="7"
              width="18"
              height="2"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
