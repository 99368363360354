import React, { Component } from 'react'
import { Link } from 'gatsby'

import {
  FlatLogo,
  Section,
  Container,
  Card,
  CardHeader,
  CardContent,
  Button
} from 'sqrl-design-system'

import LoginForm from '../forms/login'

class Login extends Component {
  render() {
    return (
      <div style={{ backgroundColor: '#f5f5f5' }}>
        <Section className="login-section">
          <Container>
            <Card style={{ maxWidth: 450, margin: 'auto' }}>
              <CardHeader
                className="center-logo"
                style={{ padding: '50px 0 40px', boxShadow: 'initial' }}
              >
                <FlatLogo fill="#9013fe" style={{ marginRight: 5 }} />
                <span style={{ color: '#202733' }}>|{'  '}Workforce</span>
              </CardHeader>
              <CardContent style={{ padding: 0 }}>
                <div className="login-wrapper">
                  <LoginForm />
                  <div className="or">or</div>
                  <Button className="full-width google-signin">
                    Sign in with Google
                  </Button>
                  <hr style={{ background: '#9013fe', height: 1 }} />
                  <p style={{ fontSize: 16 }}>
                    Want to sign your company up for SQRL?
                  </p>
                  <Link className="caps-on register-cta" to="/signup">
                    register now
                  </Link>
                </div>
              </CardContent>
            </Card>
          </Container>
        </Section>
      </div>
    )
  }
}

export default Login
