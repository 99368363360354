import React, { Component } from 'react'
import Layout from '../layout'
import Login from '../components/Login/Login'

import Header from '../components/Header'
import Footer from '../components/Footer'
import SEO from '../components/SEO'

class LoginPage extends Component {
  render() {
    return (
      <Layout>
        <SEO title='Login' />
        <Header />
        <div className="about-container">
          <Login />
        </div>
        <Footer />
      </Layout>
    )
  }
}

export default LoginPage
