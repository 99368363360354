import React from 'react'
import {
  Section,
  Container,
  Navbar,
  NavbarBrand,
  NavbarItem,
  NavbarMenu,
  NavbarBurger,
  NavbarStart,
  NavbarEnd,
  FlatLogo
} from 'sqrl-design-system'
import { Link } from 'gatsby'
import MobileMenu from '../MobileMenu'
import Logo from '../Logo'

const WorkForceLogo = () => (
  <img
    src="/logos/sqrl-ww-header-logo.svg"
    style={{ display: 'inline-block', verticalAlign: 'middle' }}
  />
)

class Header extends React.Component {
  state = {
    isActive: false
  }

  onClickNav = evt => {
    this.setState({
      isActive: !this.state.isActive
    })
  }

  render() {
    const { workforce, links } = this.props

    return (
      <header>
        <Navbar className="is-fixed-top">
          <Container>
            {!workforce && (
              <NavbarBrand>
                <div className="force-center is-hidden-desktop">
                  <NavbarItem href="/">
                    <Logo />
                  </NavbarItem>
                </div>
                <NavbarBurger
                  isActive={this.state.isActive}
                  onClick={this.onClickNav}
                />
                <NavbarItem className="navbar-brand-logo" href="/">
                  <img
                    className="is-hidden-touch"
                    src="/logos/sqrl-app-icon.svg"
                    style={{ height: 40, maxHeight: 40 }}
                  />
                </NavbarItem>
              </NavbarBrand>
            )}
            {workforce && (
              <NavbarBrand>
                <div className="force-center is-hidden-desktop">
                  <NavbarItem href="/workforce/">
                    <WorkForceLogo />
                  </NavbarItem>
                </div>
                <NavbarBurger
                  isActive={this.state.isActive}
                  onClick={this.onClickNav}
                />
                <NavbarItem className="navbar-brand-logo" href="/workforce/">
                  <div className="is-hidden-touch">
                    <WorkForceLogo />
                  </div>
                </NavbarItem>
              </NavbarBrand>
            )}

            <NavbarMenu
              isActive={this.state.isActive}
              onClick={this.onClickNav}
            >
              <NavbarEnd>
                {links &&
                  links.map((l, index) => (
                    <NavbarItem key={index}>
                      {l.to && l.to.includes('https://') && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={l.to}
                        >
                          {l.label}
                        </a>
                      )}
                      {l.to && !l.to.includes('https://') && (
                        <Link to={l.to} activeClassName="active">
                          {l.label}
                        </Link>
                      )}
                    </NavbarItem>
                  ))}
                {(!links || links.length === 0) && (
                  <>
                    <NavbarItem>
                      <Link to="/" activeClassName="active">
                        App
                      </Link>
                    </NavbarItem>

                    {/*
                      <NavbarItem>
                        <Link to="/signup/" activeClassName="active">
                          Sign Up
                        </Link>
                      </NavbarItem>
                      */}
                  </>
                )}
              </NavbarEnd>
            </NavbarMenu>
          </Container>
          <MobileMenu
            isActive={this.state.isActive}
            onClick={this.onClickNav}
          />
        </Navbar>
      </header>
    )
  }
}

export default Header
