import React from 'react'
import Logo from '../Logo'
import Close from './Close'
import { FacebookIcon, TwitterIcon, InstagramIcon } from '../Social'
import { missionStatement } from '../../resources/constants'

export default ({ isActive, onClick }) => {
  if (!isActive) {
    return null
  }

  return (
    <div className="mobile-menu is-hidden-desktop">
      <span className="close" onClick={onClick}>
        <Close />
      </span>

      <div className="logo">
        <Logo iconColor={'white'} textColor={'white'} />
      </div>

      <nav>
        <a href="/">App</a>

        <a href="/workforce/">Workforce</a>

        <a href="/faqs/">FAQ</a>

        <a
          href="https://blog.sqrl.me"
          target="_blank"
          rel="noreferrer noopener"
        >
          Blog
        </a>

        <a href="/contact-us/">Contact Us</a>
      </nav>

      <div className="note">
        <h5>Mission</h5>
        <p>{missionStatement}</p>
      </div>

      <div className="social-icons">
        <a
          href="https://www.facebook.com/SQRLme/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FacebookIcon fill={'white'} />
        </a>
        <a
          href="https://www.instagram.com/sqrlme/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <InstagramIcon fill={'white'} />
        </a>
        <a
          href="https://twitter.com/sqrlme"
          rel="noopener noreferrer"
          target="_blank"
        >
          <TwitterIcon fill={'white'} />
        </a>
      </div>

      <div className="footer">
        ©2019 Eighty4 Inc. All Rights Reserved.{' '}
        <a href="/terms-of-use/">Terms</a> |{' '}
        <a href="/privacy-policy/">Privacy</a>
      </div>
    </div>
  )
}
