import React from 'react'

export default ({ fill, ...rest }) => (
  <svg {...rest} width="11" height="22" viewBox="0 0 11 22">
    <path
      fill={fill || '#00071E'}
      fillRule="evenodd"
      d="M.351 11.717h2.387v9.928c0 .196.157.355.351.355h4.048a.353.353 0 0 0 .35-.355v-9.881h2.745a.353.353 0 0 0 .349-.314l.417-3.655a.357.357 0 0 0-.087-.277.35.35 0 0 0-.262-.119H7.488V5.11c0-.692.368-1.042 1.094-1.042h2.067A.353.353 0 0 0 11 3.712V.357a.353.353 0 0 0-.351-.354H7.8A2.776 2.776 0 0 0 7.67 0C7.175 0 5.457.098 4.1 1.36 2.599 2.756 2.808 4.43 2.858 4.72V7.4H.351A.353.353 0 0 0 0 7.753v3.608c0 .196.157.355.351.355"
    />
  </svg>
)
