import React from 'react'
import { Section, Container, FlatLogo } from 'sqrl-design-system'
import { FacebookIcon, TwitterIcon, InstagramIcon } from '../Social'

import { missionStatement } from '../../resources/constants'

export default ({ style }) => (
  <footer className="app-footer" style={style}>
    <Section>
      <Container>
        <div className="footer-logo">
          <FlatLogo style={{ marginTop: 8 }} />

          <div style={{ marginTop: 24 }}>
            <a
              href="https://www.producthunt.com/posts/sqrl-journal-step-save?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-sqrl-journal-step-save"
              target="_blank"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=166381&theme=dark"
                alt="SQRL - journal, step & save - Own your holistic well-being | Product Hunt Embed"
                style={{ width: 160, maxWidth: '100%' }}
              />
            </a>
          </div>
        </div>
        <div className="footer-company">
          <h4>Company</h4>
          <ul>
            <li>
              <a href="/">App</a>
            </li>

            <li>
              <a href="/workforce/">Workforce</a>
            </li>

            <li>
              <a href="/faqs/">FAQs</a>
            </li>

            <li>
              <a
                href="https://blog.sqrl.me"
                target="_blank"
                rel="noopener noreferrer"
              >
                Blog
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-contact">
          <h4>Contact</h4>
          <ul>
            <li>
              <a href="/contact-us/">Get In Touch</a>
            </li>
            <li>support@eighty4.co</li>
            <li>(202) 560-1013</li>
            <li>
              340 S Lemon Ave., #3756
              <br />
              Walnut, CA 91789
            </li>
          </ul>
        </div>
        <div className="footer-mission">
          <h4>Mission</h4>
          <p>{missionStatement}</p>
          <div className="hide-on-mobile">
            <hr />
            <div style={{ marginLeft: 5 }}>
              <span>&copy; 2019 Eighty4 Inc. All Rights Reserved. </span>
              <a href="/terms-of-use/">Terms</a> |{' '}
              <a href="/privacy-policy/">Privacy</a>
            </div>
            <div className="social-icons">
              <a
                href="https://www.facebook.com/SQRLme/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://www.instagram.com/sqrlme/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <InstagramIcon />
              </a>
              <a
                href="https://twitter.com/sqrlme"
                rel="noopener noreferrer"
                target="_blank"
              >
                <TwitterIcon />
              </a>
            </div>
          </div>
        </div>
      </Container>
    </Section>

    <div className="footer-copyright">
      <div>
        <span>&copy; 2019 Eighty4 Inc. All Rights Reserved.&nbsp;</span>
        <a href="/terms-of-use/">Terms</a>&nbsp;|&nbsp;
        <a href="/privacy-policy/">Privacy</a>
      </div>
    </div>
  </footer>
)
