import React from 'react'

export default ({ fill, ...rest }) => (
  <svg {...rest} width="20" height="17" viewBox="0 0 20 17">
    <path
      fill={fill || '#00071E'}
      fillRule="evenodd"
      d="M19.714 1.938a7.933 7.933 0 0 1-1.381.466A4.307 4.307 0 0 0 19.47.594a.226.226 0 0 0-.33-.263 7.97 7.97 0 0 1-2.112.872.56.56 0 0 1-.502-.125A4.319 4.319 0 0 0 13.668 0c-.456 0-.916.071-1.368.211a4.184 4.184 0 0 0-2.82 3.015 4.607 4.607 0 0 0-.102 1.59.16.16 0 0 1-.173.174A11.325 11.325 0 0 1 1.42.833a.226.226 0 0 0-.37.03 4.315 4.315 0 0 0-.585 2.17c0 1.171.47 2.275 1.287 3.08a3.87 3.87 0 0 1-.985-.382.226.226 0 0 0-.335.194 4.324 4.324 0 0 0 2.522 3.984 3.867 3.867 0 0 1-.82-.068.226.226 0 0 0-.257.29 4.329 4.329 0 0 0 3.419 2.945A7.97 7.97 0 0 1 .833 14.43h-.5a.33.33 0 0 0-.323.25.342.342 0 0 0 .164.378 11.854 11.854 0 0 0 5.957 1.606c1.83 0 3.543-.364 5.09-1.08a11.24 11.24 0 0 0 3.725-2.791 12.236 12.236 0 0 0 2.28-3.82c.507-1.354.775-2.8.775-4.18v-.066c0-.221.1-.43.275-.572a8.536 8.536 0 0 0 1.718-1.885c.125-.188-.073-.423-.28-.332"
    />
  </svg>
)
